.header-page{
   padding: 10px 0;
   background-color:#222;
   border-bottom: 1px solid black;

 }
 .header-page__container{
   display: flex;
   justify-content: space-around;
   align-items: center;


 }
 .logo{
   font-size: 42px;
 }
 .logo span{
   color:  #9084ed;
 }


 .enable-wallet:active{
   transform: scale(0.98);
 }


 .enable-wallet:active{
   transform: scale(0.96);
 }
 .enable-wallet:hover{
   color: #eee;
 }

 .enable-wallet {
   min-width: 260px;
   min-height: 60px;
   font-family: 'Nunito', sans-serif;
   font-size: 14px;
   text-transform: uppercase;
   letter-spacing: 1.3px;
   font-weight: 700;
   color: #313133;
   background: #4FD1C5;
 background: linear-gradient(90deg, rgba(129,230,217,1) 0%, rgb(151, 79, 209) 100%);
   border: none;
   border-radius: 1000px;
   box-shadow: 12px 12px 24px rgba(174, 87, 224, 0.64);
   transition: all 0.4s ease-in-out 0s;
   cursor: pointer;
   outline: none;
   position: relative;
   padding: 10px;
   }

   .enable-wallet::before {
 content: '';
   border-radius: 1000px;
   min-width: calc(300px + 12px);
   min-height: calc(60px + 12px);
   border: 6px solid #00FFCB;
   box-shadow: 0 0 60px rgba(0,255,203,.64);
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   opacity: 0;
   transition: all .3s ease-in-out 0s;
 }



 .header-page__ul{
   display: flex;
   list-style: none;
 }
 .header-page__li:not(:last-child){
   padding: 0 40px 0 0;
 }
 .header-page__link{
   text-decoration: none;
   color: #9084ed;
   font-size: 24px;
 }
 .user_info{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
 }
 .user_info-active{
  color: #9084ed;
 }
