.container-mint {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: 80vh;
  gap: 20px;
}

.gap-mintpage {
  flex-direction: column;
  display: flex;
  gap: 20px;
}

.rectangle {
  max-width: 500px;
  align-items: stretch;
  background-color: #282828;
  padding: 30px 40px;
  border-radius: 4px;
  position: absolute;
}

.name-network {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  background: rgba(255, 255, 255, 0.05);
  align-self: flex-start;
  padding: 2px 4px;
  border-radius: 4px;
  font-weight: 600;
  font-size: 0.9rem;
  color: white;
  line-height: 1.65;
}


.token-bar {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.7;
  position: relative;
}

.token-bar-text {
  background: none;
  appearance: none;
  resize: none;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  outline: rgba(255, 255, 255, 0.2) solid 1px;
}

.span-style-1 {
  visibility: hidden;
  white-space: pre-wrap;
}

.description-bar {
  font-size: 1.1rem;
  color: white;
  line-height: 1.65;
  position: relative;
}

.description-bar-text {
  background: none;
  appearance: none;
  resize: none;
  position: absolute;
  inset: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
  outline: rgba(255, 255, 255, 0.2) solid 1px;
}

.cover-asset-area {
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  gap: 10px;
  outline: rgba(255, 255, 255, 0.2) solid 1px;
  padding-top: 2px;
  padding-bottom: 2px;
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.cover-asset-area-2 {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 10px;
  min-height: 27px;
}

.cover-asset-text {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  width: 110px;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
}

.cover-asset-select {
  appearance: none;
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
  color: rgb(143, 118, 8);
  width: 0px;
  flex: 1 1 0px;
  position: relative;
  border: 0px;
  outline: none;
  min-width: 0px;
  text-align: left;
  align-self: stretch;
  border-radius: 4px;
  padding: 4px 23px 4px 8px;
  background: rgba(255, 255, 255, 0.05);
}

.price-remaining {
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: stretch;
  gap: 4px;
}

.price-body {
  display: block;
  position: relative;
  background: rgba(255, 255, 255, 0.05);
  padding: 6px;
  border-radius: 4px;
}

.price-text {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
}

.price-button {
  height: 25px;
  max-width: 26px;
  border: 2px;
  padding: 4px 6px;
  background: rgba(255, 255, 255, 0.05);
  color: rgb(248, 248, 250);
}

.price-button-image {
  min-height: 4px;
  display: flex;
  align-items: center;
  flex: 1 1 0%;
  justify-content: center;
}

.price-space {
  display: block;
  min-width: 10px;
  width: 10px;
  max-width: 10px;
}

.price-text {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: stretch;
  flex: 1 1 0%;
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 1.65;
  opacity: 0.8;
}

.abcd {
  display: block;
  flex: 1;
}

.color-text-gray {
  color: rgb(248, 248, 250);
}

.color-text-white {
  color: white;
  background: none;
}

.remaining-body {
  display: block;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  background: rgba(255, 255, 255, 0.05);
  padding: 6px;
  border-radius: 4px;
}

.remaining-text {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  flex: 1 1 0%;
}

.auction-start {
  display: flex;
  position: relative;
  flex-direction: column;
  -webkit-box-align: stretch;
  align-items: stretch;
  gap: 20px;
}

.auction-start-area {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
}

.button-minting {
  appearance: none;
  padding: 12px 16px;
  border-radius: 9px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  user-select: none;
  background: white;
  color: gray;
}

.lowbar {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  padding: 25px 40px;
  gap: 20px;
}

.lowbar-style {
  position: absolute;
  inset: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background: #282828;
}

.contract-adress {
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
  max-height: 50px;
}

.contract-adress-text {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.4;
  color: rgb(221, 221, 221);
}

.mint-adress {
  width: 800px;
  display: flex;
  position: relative;
  flex-direction: row;
  -webkit-box-align: stretch;
  align-items: stretch;
  align-self: stretch;
  gap: 10px;
  flex: 1 1 0%;
  max-width: 470px;
}

.mint-bar {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.7;
  position: relative;
  background: none;
  appearance: none;
  resize: none;
  inset: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  color: inherit;
}

.mint-bar-style {
  font-size: 0.85rem;
  font-weight: 400;
  line-height: 1.4;
  flex: 0 0 auto;
  position: relative;
  border: 0px;
  min-width: 31px;
  text-align: left;
  border-radius: 4px;
  padding: 4px 6px;
  color: rgb(248, 248, 250);
  opacity: 1;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}

.color-text-white-lowbar {
  color: white;
  height: 23px;
  width: 450px;
  resize: none;
  text-align: center;
  padding-top: 13px;
}

.wallet-area {
  display: flex;
  position: absolute;
  top: 50%;
  right: 0%;
  transform: translate(0%, -50%);
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  gap: 20px;
}

.wallet-text-style {
  font-size: 1.1rem;
  font-weight: 700;
  line-height: 1.4;
  color: rgb(221, 221, 221);
}

.account-adress-area {
  height: 50px;
  width: 190px;
  display: flex;
  position: relative;
  justify-content: center;
  border-radius: 5px;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
  background: rgb(17, 17, 17);
}

.account-adress-text {
  color: white;
  text-align: center;
  display: flex;
  align-items: center;
  padding: 10px;
}

.minting-space {
  display: flex;
  min-width: 3px;
  width: 100px;
  max-width: 100px;
}

.mint-button {
  display: flex;
  max-width: 31px;
  border: 2px;
  padding: 15px 8px;
  background: rgba(255, 255, 255, 0.05);
  color: rgb(248, 248, 250);
  height: 45.6px;
  border-radius: 5px;
}

.right-area-mintpage {
  position: relative;
}